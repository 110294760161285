import MenuBoard from '../../../components/MenuBoard';
import React from 'react';
import { DRINKS, DESSERTS, KIDS } from './../../../utils/items';
import MenuBoardSection from '../../../components/MenuBoardSection';
import LayoutMenuBoard from '../../../layouts/layout-menuboard';

export default function MenuBoard5() {
	return (
		<LayoutMenuBoard>
			<MenuBoard>
				<MenuBoardSection {...DRINKS} />
				<MenuBoardSection {...DESSERTS} />
				<MenuBoardSection {...KIDS} />
			</MenuBoard>
		</LayoutMenuBoard>
	);
}
